import { useState } from 'react';
import routes from '../routes';
import { makeStyles } from '@mui/styles';
import { Link, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { MdKeyboardArrowDown } from "react-icons/md";
import { IoLogOut } from "react-icons/io5";
import { logout } from '../redux/auth/auth.slice';
import Logo from '../assets/logo.png';
import { MdOutlineMenu } from "react-icons/md";
import { setActiveLink } from '../redux/event/event.slice';

const useStyles = makeStyles({
    name: {
        color: "#fff",
        textTransform: "capitalize",
        background: "none",
        border: "none",
        fontWeight: "bold",
        fontSize: "18px"
    },
    dropdown: {
        right: 0,
        zIndex: 10,
        position: "absolute",
        background: "#fff",
        borderRadius: "4px",
        width: "100%",
        padding: "4px 0"
    }
});

const PrimaryHeader = () => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [isDropdown, setIsDropdown] = useState(false);
    const { admin } = useSelector((state) => state.auth);
    const { activeLink } = useSelector((state) => state.event);

    const logoutClicked = () => {
        dispatch(logout());
        dispatch(setActiveLink("Events"));
        navigate("/");
    }

    const sideLink = [
        {
            name: "Dashboard",
            routeTo: routes.dashboard
        },
        {
            name: "Events",
            routeTo: routes.home
        },
        {
            name: "Users",
            routeTo: routes.user_list
        },
        {
            name: "Orders",
            routeTo: routes.order_list
        },
        {
            name: "Gallery",
            routeTo: routes.gallery
        },
        {
            name: "Contact Us",
            routeTo: routes.contact_us_list
        }
    ];

    return (
        <>
            <nav className="navbar navbar-dark fixed-top bg-custom">
                <div className="container-fluid">
                    <div className="d-flex align-items-center">
                        <button className="navbar-toggler shadow-none" type="button" data-bs-toggle="offcanvas" data-bs-target="#offcanvasExample" aria-controls="offcanvasExample">
                            <MdOutlineMenu fontSize="xx-large" />
                        </button>
                        <span className={classes.name}>{activeLink}</span>
                    </div>
                    {/* <h4 className={classes.Heading}>Welcome Admin!</h4> */}
                    <div className="position-relative">
                        <button
                            type="button"
                            className={classes.name}
                            onClick={() => setIsDropdown(!isDropdown)}>
                            Welcome {admin?.name} <MdKeyboardArrowDown />
                        </button>
                        {isDropdown && 
                            <div className={classes.dropdown}>
                                <ul className="dropdown-list">
                                    <li onClick={logoutClicked}>
                                        <IoLogOut fontSize="large" />{" "}
                                        <span className="ml-4 fw-bold">Logout</span>
                                    </li>
                                </ul>
                            </div>
                        }
                    </div>
                </div>
            </nav>
            <div className="offcanvas offcanvas-start" tabIndex="-1" id="offcanvasExample" aria-labelledby="offcanvasExampleLabel">
                <div className="offcanvas-header">
                    <img src={Logo} alt="Logo" width="44" />
                    <button type="button" className="btn-close text-reset" data-bs-dismiss="offcanvas" aria-label="Close"></button>
                </div>
                <div className="offcanvas-body">
                    <div className="d-flex justify-content-between">
                        <Link 
                            onClick={() => dispatch(setActiveLink("Create New Event"))}
                            data-bs-dismiss="offcanvas"
                            className="btn btn-outline-primary primary-button"
                            to={routes.create_new_event}>
                            Create New Event
                        </Link>
                        <Link 
                            onClick={() => dispatch(setActiveLink("Event Template"))}
                            data-bs-dismiss="offcanvas"
                            className="btn btn-outline-primary primary-button"
                            to={routes.event_template}>
                            Event Template
                        </Link>
                    </div>
                    <div className="dropdown mt-3 side-menu">
                        <ul>
                            {sideLink.map((item, index) => (
                                <li
                                    key={index}
                                    onClick={() => dispatch(setActiveLink(item.name))}
                                    className={item.name === activeLink ? 'active-link' : 'inactive-link'}>
                                    <Link
                                        to={item.routeTo}
                                        data-bs-dismiss="offcanvas"
                                        className={item.name === activeLink ? 'active-link' : 'inactive-link'}>
                                        {item.name}
                                    </Link>
                                </li>
                            ))}
                            {admin?.email === "admin@thriftyx.com" &&
                                <li
                                    key={sideLink?.length}
                                    onClick={() => dispatch(setActiveLink("Sub Admin"))}
                                    className={activeLink === "Sub Admin" ? 'active-link' : 'inactive-link'}
                                >
                                    <Link
                                        to={routes.sub_admin_list}
                                        data-bs-dismiss="offcanvas"
                                        className={activeLink === "Sub Admin" ? 'active-link' : 'inactive-link'}
                                    >
                                        Sub Admin
                                    </Link>
                                </li>
                            }
                        </ul>
                    </div>
                </div>
            </div>
        </>
    )
}

export default PrimaryHeader;