import { configureStore } from '@reduxjs/toolkit';
import authSlice from './auth/auth.slice';
import eventSlice from './event/event.slice';
import userSlice from './user/user.slice';

const store = configureStore({
    reducer: {
        auth: authSlice,
        event: eventSlice,
        user: userSlice
    }
});

export default store;