import { useState } from 'react';
import { toast } from "react-toastify";
import "react-datepicker/dist/react-datepicker.css";
import 'react-time-picker/dist/TimePicker.css';
import 'react-clock/dist/Clock.css';
import { useDispatch, useSelector } from 'react-redux';
import uploadOnS3 from '../redux/uploadOnS3';
import Loader from '../assets/loader.gif';
import PageLoader from './PageLoader';
import { addGalleryImage } from '../redux/event/event.slice';

const formData = {
    title:"",
    category: "",
    image:"",
}

const GalleryForm = ({from}) => {
    const dispatch = useDispatch();

    const { isLoader } = useSelector((state) => state.event);

    const [galleryData, setGalleryData] = useState(formData);

    const handleChange = async(e) => {
        e.target.name === "title" && setGalleryData({...galleryData, title: e.target.value});
        e.target.name === "category" && setGalleryData({...galleryData, category: e.target.value});
        if(e.target.name === "file"){
            if(e.target.files[0].type === "image/jpeg" || 
                e.target.files[0].type === "image/svg+xml" ||
                e.target.files[0].type === "image/png"){
                if(e.target.files[0].size > 1049000){
                    toast.error("Image size must be less than 1MB");
                    return;
                }
                else{
                    let res = await uploadOnS3(e.target.files[0]);
                    if(res?.statusCode === 200){
                        setGalleryData({...galleryData, image: res?.data?.urls[0]})
                    }else{
                        toast.error("Error while uploading file");
                    }
                }
                return;
            }
            toast.error("Invalid file type");
        }
    }

    const handleSubmit = () => {
        if(formValidator().isValid){
            if(from === "edit"){
                // let body = {
                //     name: eventData.name,
                //     type: eventData.type,
                //     city: eventData.city,
                //     venue: eventData.venue,
                //     image: eventData.image,
                //     date: eventData.date,
                //     time: eventData.time,
                //     age_limit: eventData.age_limit,
                //     description: eventData.description,
                //     address: eventData.address,
                //     tickets: eventData.tickets,
                //     status: eventData.status,
                // }
                // const res = dispatch(updateEvent({body, id: eventData._id}));
                // res?.then((r) => {
                //     if(r?.payload?.statusCode === 200){
                //         toast.success(r?.payload?.message);
                //         return;
                //     }
                //     toast.error(r?.payload);
                // });
                // return;
            }
            else{
                const res = dispatch(addGalleryImage(galleryData));
                res?.then((r) => {
                    if(r?.payload?.statusCode === 200){
                        setGalleryData({...formData});
                        toast.success(r?.payload?.message);
                        return;
                    }
                    toast.error(r?.payload);
                });
                return;
            }
        }
        toast.error(formValidator().message);
    }

    const formValidator = () => {
        let status = {isValid: true, message: ""};
        if(galleryData.title === "" || !galleryData.title){
            status.isValid = false;
            status.message = "Please enter image title";
            return status;
        }
        if(galleryData.category === "" || !galleryData.category){
            status.isValid = false;
            status.message = "Please enter image category";
            return status;
        }
        if(galleryData.image === "" || !galleryData.image){
            status.isValid = false;
            status.message = "Please upload image";
            return status;
        }
        return status;
    }

    if(isLoader) return ( <PageLoader />)

    return (
        <div className="event-form-container">
            <div className="mt-80 w-50">
                <h4 className="text-center">
                    Add Gallery Image
                </h4>
                <div>
                    <div className="mb-3 form-box">
                        <label htmlFor="name" className="form-label">
                            Title
                        </label>
                        <input
                            type="text" 
                            id="title"
                            name="title"
                            value={galleryData.title}
                            onChange={handleChange}
                            className="form-control"
                            placeholder="Enter event name" />
                    </div>
                    <div className="mb-3 form-box">
                        <label htmlFor="name" className="form-label">
                            Category
                        </label>
                        <input
                            type="text" 
                            id="category"
                            name="category"
                            value={galleryData.category}
                            onChange={handleChange}
                            className="form-control"
                            placeholder="Enter event name" />
                    </div>
                    <div className="mb-3 form-box">
                        <label htmlFor="address" className="form-label">
                            Image
                        </label>
                        <input 
                            type="file" 
                            className="form-control" 
                            id="file"
                            name="file"
                            onChange={handleChange} />
                        {galleryData.image && 
                            <div className="event-image">
                                <img src={galleryData.image} alt="gallery_image" />
                            </div>
                        }
                    </div>
                    {(!from || from === "edit") &&
                        <button
                            type="button"
                            disabled={isLoader}
                            onClick={handleSubmit}
                            className={`btn primary-button-2 ${isLoader && 'btn-disabled'}`}>
                            {isLoader ? <img src={Loader} alt="Loader" /> : (from === "edit" ? "Save Changes" : "Submit")}
                        </button>
                    }
                </div>
            </div>
        </div>
    )
}

export default GalleryForm;