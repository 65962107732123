import Table from "./Table";
import PageLoader from './PageLoader';
import { useEffect, useState, useCallback, useMemo } from "react";
import { makeStyles } from "@mui/styles";
import { FaSearch } from "react-icons/fa";
import { useDispatch, useSelector } from "react-redux";
import { getOrders } from "../redux/user/user.slice";
import debounce from "lodash.debounce";

const useStyles = makeStyles({
    searchInput: {
        marginBottom: 16,
    },
    searchIcon: {
        right: 16,
        top: 4
    }
});

const heading = ["SNo.", "Order Id", "Receipt No", "Date", "Status", "Billing Detail", "User Detail", "Event Detail"];

const OrderList = () => {
    const dispatch = useDispatch();
    const classes = useStyles();
    const [limit, setLimit] = useState(10);
    const [offset, setOffset] = useState(0);
    const [searchText, setSearchText] = useState("");

    const { isLoader, totalCount, orderData } = useSelector((state) => state.user);

    useEffect(() => {
        dispatch(getOrders({limit, offset, searchText}));
    },[dispatch, limit, offset, searchText]);

    const debouncedSave = useMemo(() => 
        debounce((value) => setSearchText(value), 1000), 
        [setSearchText]
    );

    const handleSearchText = useCallback((event) => {
        if(limit === 10 && offset === 0){
            debouncedSave(event.target.value);
        }
        else{
            setLimit(10);
            setOffset(0);
        }
    }, [limit, offset, debouncedSave]);

    const nextClicked = (l, o) => {
        setLimit(l+10);
        setOffset(o+10);
    }

    const prevClicked = (l, o) => {
        setLimit(l-10);
        setOffset(o-10);
    }

    if(isLoader) return ( <PageLoader />)

    return (
        <>
            <div className="container mt-80">
                <div className="position-relative">
                    <div className={`${classes.searchIcon} position-absolute`}>
                        <FaSearch />
                    </div>
                    <input
                        type="text"
                        onChange={handleSearchText}
                        className={`${classes.searchInput} form-control`} 
                        placeholder="Search for a order" 
                    />
                </div>
                <Table 
                    data={orderData}
                    heading={heading}
                    limit={limit}
                    offset={offset}
                    tableFor="orders"
                    totalCount={totalCount}
                    previous={(limit, offset) => prevClicked(limit, offset)}
                    next={(limit, offset) => nextClicked(limit, offset)}
                />
            </div>
        </>
    )
}
export default OrderList;