import axios from "axios";
import routes from "../routes";

const addEvent = async(data) => {
    const res = await axios.post(routes.EVENT, data, {
        headers: { Authorization: localStorage.getItem("token") },
    });
    return res.data
}

const updateEvent = async(params) => {
    const { body, id } = params;
    const res = await axios.put(routes.EVENT+"/"+id, body, {
        headers: { Authorization: localStorage.getItem("token") },
    });
    return res.data
}

const getEvents = async(params) => {
    let { limit, offset, date, searchText } = params;
    const res = await axios.get(routes.EVENT, {
        params: { limit, offset, date, searchText },
        headers: { Authorization: localStorage.getItem("token") }
    });
    return res.data;
}

const getEventById = async(params) => {
    let { id } = params;
    const res = await axios.get(routes.GET_EVENT+"/"+id, {
        headers: { Authorization: localStorage.getItem("token") }
    });
    return res.data;
}

const addGalleryImage = async(data) => {
    const res = await axios.post(routes.GALLERY_ADMIN, data, {
        headers: { Authorization: localStorage.getItem("token") }
    });
    return res.data;
}

const getGallery = async(params) => {
    let { limit, offset, searchText } = params;
    const res = await axios.get(routes.GALLERY, {
        params: { limit, offset, searchText },
        headers: { Authorization: localStorage.getItem("token") }
    });
    return res.data;
}

const deleteImage = async(id) => {
    const res = await axios.delete(routes.GALLERY_ADMIN+"/"+id, {
        headers: { Authorization: localStorage.getItem("token") }
    });
    return res.data;
}

const eventServices = { 
    addEvent,
    getEvents,
    getGallery,
    deleteImage,
    updateEvent,
    getEventById,
    addGalleryImage
}

export default eventServices;