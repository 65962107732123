import './App.css';
import routes from './routes';
import Home from './view/Home';
import Login from './view/Login';
import Order from './view/Order';
import Users from './view/Users';
import Gallery from './view/Gallery';
import SubAdmin from './view/SubAdmin';
import NotFound from './view/NotFound';
import ScrollToTop from './ScrollToTop';
import Layout from './component/Layout';
import ContactUs from './view/ContactUs';
import ViewEvent from './view/ViewEvent';
import EditEvent from './view/EditEvent';
import { useSelector } from 'react-redux';
import EventTemplate from './view/EventTemplate';
import DashboardView from './view/Dashboard';
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer } from "react-toastify";
import CreateNewEvent from './view/CreateNewEvent';
import AddGalleryImage from './view/AddGalleryImage';
import CreateSubAdmin from './view/CreateAdmin';
import { Routes, Route, Navigate } from "react-router";

function App() {
  const { admin } = useSelector((state) => state.auth);

  return (
    <>
      <ScrollToTop />
      <Routes>
        {!admin && <Route element={<Login />} path={routes.login} />}
        <Route path="/" element={admin ? <Layout /> : <Navigate to={routes.login} replace />}>
          <Route element={<Home />} path={routes.home} />
          <Route element={<Gallery />} path={routes.gallery} />
          <Route element={<Users />} path={routes.user_list} />
          <Route element={<Order />} path={routes.order_list} />
          <Route element={<ViewEvent />} path={routes.event_view} />
          <Route element={<SubAdmin />} path={routes.sub_admin_list} />
          <Route element={<EditEvent />} path={routes.event_edit} />
          <Route element={<EventTemplate />} path={routes.event_template} />
          <Route element={<DashboardView />} path={routes.dashboard} />
          <Route element={<CreateSubAdmin />} path={routes.create_new_admin} />
          <Route element={<ContactUs />} path={routes.contact_us_list} />
          <Route element={<AddGalleryImage />} path={routes.add_gallery_img} />
          <Route element={<CreateNewEvent />} path={routes.create_new_event} />
        </Route>
        <Route path="*" element={<NotFound />} />
      </Routes>
      <ToastContainer
        position="bottom-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </>
  );
}

export default App;
