import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import userServices from './user.services';

const initialState = {
    isError: false,
    isSuccess: false,
    isLoading: false,
    message: "",
    userData: null,
    userById: null,
    contactUsData: null,
    totalCount: 0,
    orderData: null,
    subAdminData: null
};

export const getUsers = createAsyncThunk('user/getUsers', async(params, thunkAPI) => {
    try{
        return await userServices.getUsers(params);
    }
    catch(error){
        const message =
            (error.response && error.response.data && error.response.data.message) ||
            error.message ||
            error.toString();
        return thunkAPI.rejectWithValue(message);
    }
});

export const getUserById = createAsyncThunk('user/getUserById', async(params, thunkAPI) => {
    try{
        return await userServices.getUserById(params);
    }
    catch(error){
        const message =
            (error.response && error.response.data && error.response.data.message) ||
            error.message ||
            error.toString();
        return thunkAPI.rejectWithValue(message);
    }
});

export const getContactUs = createAsyncThunk('user/getContactUs', async(params, thunkAPI) => {
    try{
        return await userServices.getContactUs(params);
    }
    catch(error){
        const message =
            (error.response && error.response.data && error.response.data.message) ||
            error.message ||
            error.toString();
        return thunkAPI.rejectWithValue(message);
    }
});

export const getOrders = createAsyncThunk('user/getOrders', async(params, thunkAPI) => {
    try{
        return await userServices.getOrders(params);
    }
    catch(error){
        const message =
            (error.response && error.response.data && error.response.data.message) ||
            error.message ||
            error.toString();
        return thunkAPI.rejectWithValue(message);
    }
});

export const getSubAdmins = createAsyncThunk('user/getSubAdmins', async(params, thunkAPI) => {
    try{
        return await userServices.getSubAdmins(params);
    }
    catch(error){
        const message =
            (error.response && error.response.data && error.response.data.message) ||
            error.message ||
            error.toString();
        return thunkAPI.rejectWithValue(message);
    }
});

export const addSubAdmin = createAsyncThunk('user/addSubAdmin', async(params, thunkAPI) => {
    try{
        return await userServices.addSubAdmin(params);
    }
    catch(error){
        const message =
            (error.response && error.response.data && error.response.data.message) ||
            error.message ||
            error.toString();
        return thunkAPI.rejectWithValue(message);
    }
});

export const deleteSubAdmin = createAsyncThunk('user/deleteSubAdmin', async(params, thunkAPI) => {
    try{
        return await userServices.deleteSubAdmin(params);
    }
    catch(error){
        const message =
            (error.response && error.response.data && error.response.data.message) ||
            error.message ||
            error.toString();
        return thunkAPI.rejectWithValue(message);
    }
});

export const updateSubAdmin = createAsyncThunk('user/updateSubAdmin', async(params, thunkAPI) => {
    try{
        return await userServices.updateSubAdmin(params);
    }
    catch(error){
        const message =
            (error.response && error.response.data && error.response.data.message) ||
            error.message ||
            error.toString();
        return thunkAPI.rejectWithValue(message);
    }
});

const userSlice = createSlice({
    name: 'user',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(getUsers.pending, (state) => {
                state.isLoading = true;
            })
            .addCase(getUsers.rejected, (state, action) => {
                state.isLoading = false;
                state.isError = true;
                state.message = action.payload;
            })
            .addCase(getUsers.fulfilled, (state, action) => {
                state.isLoading = false;
                state.isError = false;
                state.isSuccess = true;
                state.message = action.payload;
                state.userData = action.payload.data.result;
                state.totalCount = action.payload.data.totalCount;
            })
            .addCase(getUserById.pending, (state) => {
                state.isLoading = true;
            })
            .addCase(getUserById.rejected, (state, action) => {
                state.isLoading = false;
                state.isError = true;
                state.message = action.payload;
            })
            .addCase(getUserById.fulfilled, (state, action) => {
                state.isLoading = false;
                state.isError = false;
                state.isSuccess = true;
                state.message = action.payload;
                state.userById = action.payload.data;
            })
            .addCase(getContactUs.pending, (state) => {
                state.isLoading = true;
            })
            .addCase(getContactUs.rejected, (state, action) => {
                state.isLoading = false;
                state.isError = true;
                state.message = action.payload;
            })
            .addCase(getContactUs.fulfilled, (state, action) => {
                state.isLoading = false;
                state.isError = false;
                state.isSuccess = true;
                state.message = action.payload;
                state.contactUsData = action.payload.data.result;
                state.totalCount = action.payload.data.totalCount;
            })
            .addCase(getOrders.pending, (state) => {
                state.isLoading = true;
            })
            .addCase(getOrders.rejected, (state, action) => {
                state.isLoading = false;
                state.isError = true;
                state.message = action.payload;
            })
            .addCase(getOrders.fulfilled, (state, action) => {
                state.isLoading = false;
                state.isError = false;
                state.isSuccess = true;
                state.message = action.payload;
                state.orderData = action.payload.data.result;
                state.totalCount = action.payload.data.totalCount;
            })
            .addCase(getSubAdmins.pending, (state) => {
                state.isLoading = true;
            })
            .addCase(getSubAdmins.rejected, (state, action) => {
                state.isLoading = false;
                state.isError = true;
                state.message = action.payload;
            })
            .addCase(getSubAdmins.fulfilled, (state, action) => {
                state.isLoading = false;
                state.isError = false;
                state.isSuccess = true;
                state.message = action.payload;
                state.subAdminData = action.payload.data.result;
                state.totalCount = action.payload.data.totalCount;
            })
            .addCase(addSubAdmin.pending, (state) => {
                state.isLoading = true;
            })
            .addCase(addSubAdmin.rejected, (state, action) => {
                state.isLoading = false;
                state.isError = true;
                state.message = action.payload;
            })
            .addCase(addSubAdmin.fulfilled, (state, action) => {
                state.isLoading = false;
                state.isError = false;
                state.isSuccess = true;
                state.message = action.payload;
            })
            .addCase(deleteSubAdmin.pending, (state) => {
                state.isLoading = true;
            })
            .addCase(deleteSubAdmin.rejected, (state, action) => {
                state.isLoading = false;
                state.isError = true;
                state.message = action.payload;
            })
            .addCase(deleteSubAdmin.fulfilled, (state, action) => {
                state.isLoading = false;
                state.isError = false;
                state.isSuccess = true;
                state.message = action.payload;
            })
            .addCase(updateSubAdmin.pending, (state) => {
                state.isLoading = true;
            })
            .addCase(updateSubAdmin.rejected, (state, action) => {
                state.isLoading = false;
                state.isError = true;
                state.message = action.payload;
            })
            .addCase(updateSubAdmin.fulfilled, (state, action) => {
                state.isLoading = false;
                state.isError = false;
                state.isSuccess = true;
                state.message = action.payload;
            })
    }
});

export default userSlice.reducer;