import { useState } from "react";
import validator from "validator";
import Logo from "../assets/logo.png";
import { toast } from "react-toastify";
import routes from "../routes";
import { IoEye } from "react-icons/io5";
import { IoEyeOff } from "react-icons/io5";
import { useDispatch, useSelector } from "react-redux";
import { login } from "../redux/auth/auth.slice";
import { useNavigate } from "react-router-dom";
import Loader from '../assets/loader.gif';

let data = {
    email: "",
    password: "",
}

const LoginForm = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const {isLoading} = useSelector((state) => state.auth);
    const [adminInfo, setAdminInfo] = useState(data);
    const [isPasswordHidden, setIsPasswordHidden] = useState(false);

    const loginClicked = () => {
        if(!adminInfo.email || adminInfo.email === "") return toast.error("Email Required");
        if(!validator.isEmail(adminInfo.email)) return toast.error("Invalid Email Entered");
        if(!adminInfo.password || adminInfo.password === "") return toast.error("Password Required");
        const res = dispatch(login(adminInfo));
        res?.then((r) => {
            if(r?.payload?.statusCode === 200){
                toast.success(r?.payload?.message);
                navigate(routes.home);
                return;
            }
            toast.error(r?.payload?.message || r?.payload);
        })

    }

    const handleChange = (e) => {
        e.target.name === "email" && setAdminInfo({...adminInfo, email: e.target.value});
        e.target.name === "password" && setAdminInfo({...adminInfo, password: e.target.value});
    }

    return (
        <div className="d-flex">
            <div className="w-50 vh-100 company-detail">
                <img src={Logo} alt="Logo" />
            </div>
            <div className="w-50 vh-100 login-form">
                <h2>Login</h2>
                <div className="w-75">
                    <div className="mb-3 form-box">
                        <label htmlFor="email" className="form-label">
                            Email
                        </label>
                        <input 
                            type="email" 
                            className="form-control" 
                            id="email" 
                            name="email"
                            value={adminInfo.email}
                            onChange={handleChange}
                            placeholder="Enter Email" />
                    </div>
                    <div className="mb-3 form-box">
                        <label htmlFor="password" className="form-label">
                            Password
                        </label>
                        <div className="position-relative">
                            <input 
                                type={!isPasswordHidden ? "password" : "text"}
                                className="form-control" 
                                id="password" 
                                name="password"
                                value={adminInfo.password}
                                onChange={handleChange}
                                placeholder="Enter Password" 
                            />
                            <button
                                type="button"
                                className="btn-hide-show"
                                onClick={() => setIsPasswordHidden(!isPasswordHidden)}>
                                {isPasswordHidden ? <IoEyeOff size="24" /> : <IoEye size="24" />}
                            </button>
                        </div>
                    </div>
                    <button
                        disabled={isLoading}
                        onClick={loginClicked}
                        className={`btn primary-button-2 ${isLoading && 'btn-disabled'}`}
                        type="button">
                        {isLoading ? <img src={Loader} alt="Loader" /> : "Login"}
                    </button>
                </div>
            </div>
        </div>
    )
}
export default LoginForm;