import { useEffect } from 'react';
import AppHeader from "../view/AppHeader";
import { Outlet } from "react-router-dom";
import { useDispatch } from 'react-redux';
import { toast } from "react-toastify";
import { useNavigate } from "react-router";
import { logout } from '../redux/auth/auth.slice';
import { verifyToken } from '../redux/auth/auth.slice';

const Layout = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();

    useEffect(() => {
        if(localStorage.getItem("token")){
          const res = dispatch(verifyToken());
          res?.then((r) => {
            if(r?.payload === "Session Expired"){
                toast.error("Session Expired");
                dispatch(logout());
                navigate("/");
            }
          })
        }
    }, [dispatch, navigate]);

    return (
        <>
            <AppHeader />
            <Outlet />
        </>
    )
}

export default Layout;