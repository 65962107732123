import { useState, useEffect } from "react";
import EventDetails from "../component/EventDetails";
import PageLoader from '../component/PageLoader';
import { useDispatch, useSelector } from "react-redux";
import { getEvents, getEventById } from "../redux/event/event.slice";

const ViewEvent = () => { 
    const dispatch = useDispatch();
    const [stableLoader, setStableLoader] = useState(false);
    const { 
        isLoading, 
        eventId } = useSelector((state) => state.event);

    useEffect(() => {
        eventId && localStorage.setItem('eventId', eventId);
        let id = eventId ? eventId : localStorage.getItem('eventId');
        const res = dispatch(getEventById({id}));
        res.then((r) => { 
            setStableLoader(false);
            if(r?.payload?.statusCode === 200){
                dispatch(getEvents({
                    searchText: r?.payload?.data?.city, 
                    limit: 4, 
                    offset: 0
                }));
            }
        });
    },[eventId, dispatch]);

    if(isLoading && stableLoader) return ( <PageLoader /> );

    return (<EventDetails />)
}
export default ViewEvent;