import { makeStyles } from "@mui/styles";
import Loader from '../assets/page-loader.gif';

const useStyles = makeStyles({
    root: {
        top: 0,
        left: 0,
        zIndex: 10000,
        display: 'flex',
        height: '100%',
        width: '100%',
        position: 'absolute',
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: 'rgba(0, 0, 0, 0.3)',
    },
    loader: {
        height: 200,
        mixBlendMode: 'soft-light'
    }
});

const PageLoader = () => {
    const classes = useStyles();
    return (
        <>
            <div className={classes.root}>
                <img src={Loader} alt="Loader" className={classes.loader} />
            </div>
        </>
    )
}
export default PageLoader;