import Logo from "../assets/logo.png";

const NotFound = () => {
    return (
        <div className="container page-not-found">
            <img src={Logo} alt="Logo" width="100" />
            <h3>404</h3>
            <h4>Page Not Found</h4>
        </div>
    )
}
export default NotFound;