import Table from "./Table";
import routes from '../routes';
import { makeStyles } from "@mui/styles";
import { FaSearch } from "react-icons/fa";
import PageLoader from './PageLoader';
import debounce from "lodash.debounce";
import { CgDanger } from "react-icons/cg";
import { toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useMemo, useState, useCallback } from "react";
import { getSubAdmins, deleteSubAdmin, updateSubAdmin } from "../redux/user/user.slice";

const heading = ["SNo.", "Name", "Email", "status", "Action"];

const useStyles = makeStyles({
    searchInput: {
        marginBottom: 16,
    },
    searchIcon: {
        right: 16,
        top: 4
    },
    arertHeading: {
        textAlign: 'center',
        fontSize: 20,
        fontWeight: 'bold'
    },
    alertDetail: {
        fontSize: 17,
        textAlign: 'center',
    }
});

const passwordData = {
    newPassword: "",
    reNewPassword: ""
}

const SubAdminList = () => {

    const classes = useStyles();
    const dispatch = useDispatch();
    const [limit, setLimit] = useState(10);
    const [offset, setOffset] = useState(0);
    const [subAdminId, setSubAdminId] = useState("");
    const [searchText, setSearchText] = useState("");
    const [adminStatus, setAdminStatus] = useState("");
    const [isModalDelete, setModalDelete] = useState(false);
    const [isModalSuspend, setIsModalSuspend] = useState(false);
    const [isModalPassword, setIsModalPassword] = useState(false);
    const [newPasswordData, setNewPasswordData] = useState(passwordData);

    const { subAdminData, isLoading } = useSelector((state) => state.user);

    useEffect(() => {
        console.log("admin")
        dispatch(getSubAdmins({limit, offset, searchText}));
    },[limit, offset, searchText, dispatch]);

    const debouncedSave = useMemo(() => 
        debounce((value) => setSearchText(value), 1000), 
        [setSearchText]
    );

    const handleSearchText = useCallback((event) => {
        if(limit === 10 && offset === 0){
            debouncedSave(event.target.value);
        }
        else{
            setLimit(10);
            setOffset(0);
        }
    }, [limit, offset, debouncedSave]);

    const nextClicked = (l, o) => {
        setLimit(l+10);
        setOffset(o+10);
    }

    const prevClicked = (l, o) => {
        setLimit(l-10);
        setOffset(o-10);
    }

    const deleteSubAdminClicked = (id) => {
        setSubAdminId(id);
        setIsModalSuspend(false);
        setIsModalPassword(false);
        setModalDelete(true);
    }

    const toggleSuspend = (id, status) => {
        setAdminStatus(status);
        setSubAdminId(id);
        setModalDelete(false);
        setIsModalPassword(false);
        setIsModalSuspend(true);
    }

    const updatePassword = (id) => {
        setSubAdminId(id);
        setModalDelete(false);
        setIsModalSuspend(false);
        setIsModalPassword(true);
    }

    const confirmClicked = () => {
        const res = dispatch(deleteSubAdmin(subAdminId));
        res?.then((r) => {
            if(r?.payload.statusCode === 200){
                toast.success("Sub Admin Deleted Successfully");
                dispatch(getSubAdmins({limit, offset, searchText}));
                return;
            }
            toast.error(r?.payload?.message);
        })
    }

    const confirmSuspendToggle = () => {
        let adminBody = {
            id: subAdminId,
            body: {status: adminStatus === "active" ? "suspended" : "active"}
        }
        const res = dispatch(updateSubAdmin(adminBody));
        res?.then((r) => {
            if(r?.payload.statusCode === 200){
                toast.success(
                    adminStatus === "active" ? 
                    "Admin Suspended Successfully" :
                    "Admin Activated Successfully"
                );
                dispatch(getSubAdmins({limit, offset, searchText}));
                return;
            }
            toast.error(r?.payload?.message);
        })
    }

    const handleChange = (e) => {
        e.target.name === "new-password" && setNewPasswordData({...newPasswordData, newPassword: e.target.value});
        e.target.name === "re-new-password" && setNewPasswordData({...newPasswordData, reNewPassword: e.target.value});
    }

    const changePassword = () => {
        if(formValidator().isValid){
            let adminBody = {
                id: subAdminId,
                body: {password: newPasswordData.newPassword}
            }
            const res = dispatch(updateSubAdmin(adminBody));
            res?.then((r) => {
                if(r?.payload.statusCode === 200){
                    setNewPasswordData({...passwordData});
                    toast.success("Password Changed Successfully");
                    dispatch(getSubAdmins({limit, offset, searchText}));
                    return;
                }
                toast.error(r?.payload?.message);
            })
            return;
        }
        toast.error(formValidator().message);
    }

    const formValidator = () => {
        let status = {isValid: true, message: ""};

        if(newPasswordData.newPassword === "" || !newPasswordData.newPassword){
            status.isValid = false;
            status.message = "Please enter password";
            return status;
        }
        if(newPasswordData.newPassword.length < 8){
            status.isValid = false;
            status.message = "Password must be greater than 8 characters";
            return status;
        }
        if(!newPasswordData.newPassword.match(/[a-z]/g)){
            status.isValid = false;
            status.message = "Password must have atleast a lowercase";
            return status;
        }
        if(!newPasswordData.newPassword.match(/[A-Z]/g)){
            status.isValid = false;
            status.message = "Password must have atleast an uppercase";
            return status;
        }
        if(!newPasswordData.newPassword.match(/[0-9]/g)){
            status.isValid = false;
            status.message = "Password must have atleast a number";
            return status;
        }
        if(!newPasswordData.newPassword.match(/([$&+,:;=?@#|'<>.^*()%!-])/g)){
            status.isValid = false;
            status.message = "Password must have atleast an special character";
            return status;
        }
        if(newPasswordData.newPassword !== newPasswordData.reNewPassword){
            status.isValid = false;
            status.message = "New password and re-enter pasword are not same";
            return status;
        }
        return status;
    }

    if(isLoading) return ( <PageLoader />)

    return (
        <>
            <div className="container mt-80">
                <div className="position-relative">
                    <div className={`${classes.searchIcon} position-absolute`}>
                        <FaSearch />
                    </div>
                    <input
                        type="text"
                        onChange={handleSearchText}
                        className={`${classes.searchInput} form-control`}
                        placeholder="Search sub admin"
                    />
                </div>
                <Table 
                    data={subAdminData}
                    heading={heading}
                    limit={limit}
                    offset={offset}
                    tableFor="sub admin"
                    isBtnCreate={true}
                    btnText="Create New Admin"
                    totalCount={subAdminData?.length}
                    updatePassword={(id) => updatePassword(id)}
                    toggleSuspend={(id, status) => toggleSuspend(id, status)}
                    btnRoute={routes.create_new_admin}
                    deleteSubAdmin={(id) => deleteSubAdminClicked(id)}
                    previous={(limit, offset) => prevClicked(limit, offset)}
                    next={(limit, offset) => nextClicked(limit, offset)}
                />
            </div>
            <div
                id="modal"
                className="modal fade" 
                data-bs-keyboard="false" 
                aria-hidden="false">
                <div className="modal-dialog modal-dialog-centered">
                    {isModalDelete &&
                        <div className="modal-content">
                            <div className="modal-header">
                                <h1 className="modal-title fs-5 fw-bold text-danger">
                                    Confirm
                                </h1>
                                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                            </div>
                            <div className="modal-body user-detail">
                                <div className="text-center">
                                    <CgDanger fontSize={48} className="text-danger" />
                                </div>
                                <p className={`${classes.arertHeading} text-danger`}>
                                    Are you sure ?
                                </p>
                                <p className={classes.alertDetail}>
                                    You want to delete the admin.
                                </p>
                            </div>
                            <div className="modal-footer">
                                <button
                                    data-bs-dismiss="modal"
                                    className="btn btn-secondary">
                                    Cancel
                                </button>
                                <button
                                    data-bs-dismiss="modal"
                                    onClick={confirmClicked}
                                    className="btn btn-danger">
                                    Proceed
                                </button>
                            </div>
                        </div>
                    }
                    {isModalSuspend &&
                        <div className="modal-content">
                            <div className="modal-header">
                                <h1 className="modal-title fs-5 fw-bold text-danger">
                                    Confirm
                                </h1>
                                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                            </div>
                            <div className="modal-body user-detail">
                                <div className="text-center">
                                    <CgDanger fontSize={48} className="text-danger" />
                                </div>
                                <p className={`${classes.arertHeading} text-danger`}>
                                    Are you sure ?
                                </p>
                                <p className={classes.alertDetail}>
                                    You want to {adminStatus === "active" ? "suspend" : "activate"} the admin.
                                </p>
                            </div>
                            <div className="modal-footer">
                                <button
                                    data-bs-dismiss="modal"
                                    className="btn btn-secondary">
                                    Cancel
                                </button>
                                <button
                                    data-bs-dismiss="modal"
                                    onClick={confirmSuspendToggle}
                                    className="btn btn-danger">
                                    Proceed
                                </button>
                            </div>
                        </div>
                    }
                    {isModalPassword &&
                        <div className="modal-content">
                            <div className="modal-header">
                                <h1 className="modal-title fs-5 fw-bold text-danger">
                                    Create New Password
                                </h1>
                                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                            </div>
                            <div className="modal-body user-detail">
                                <div className="mb-3 form-box">
                                    <label className="form-label">
                                        New Password
                                    </label>
                                    <input
                                        name="new-password"
                                        onChange={handleChange}
                                        className="form-control"
                                        value={newPasswordData.newPassword}
                                    />
                                </div>
                                <div className="mb-3 form-box">
                                    <label className="form-label">
                                        Re-Enter New Password
                                    </label>
                                    <input
                                        name="re-new-password"
                                        onChange={handleChange}
                                        className="form-control"
                                        value={newPasswordData.reNewPassword}
                                    />
                                </div>
                            </div>
                            <div className="modal-footer">
                                <button
                                    data-bs-dismiss="modal"
                                    className="btn btn-secondary">
                                    Cancel
                                </button>
                                <button
                                    onClick={changePassword}
                                    className="btn btn-danger">
                                    Proceed
                                </button>
                            </div>
                        </div>
                    }
                </div>
            </div>

        </>
    )
}

export default SubAdminList;