import { useEffect } from "react";
import EventForm from "../component/EventForm";
import { useDispatch, useSelector } from "react-redux";
import { setEventByIdData } from "../redux/event/event.slice";

const CreateNewEvent = () => { 
    const dispatch = useDispatch();
    const { eventById } = useSelector((state) => state.event);

    useEffect(() => { dispatch(setEventByIdData(null)) }, [dispatch]);

    return (!eventById && <EventForm />)
}
export default CreateNewEvent;