import axios from "axios";
import routes from "../routes";

const getUsers = async(params) => {
    let { limit, offset, searchText } = params
    const res = await axios.get(routes.USER, {
        params: { limit, offset, searchText },
        headers: { Authorization: localStorage.getItem("token") }
    });
    return res.data;
}

const getUserById = async(params) => {
    const res = await axios.get(routes.USER+"/"+params, {
        headers: { Authorization: localStorage.getItem("token") }
    });
    return res.data;
}

const getContactUs = async(params) => {
    let { limit, offset, searchText } = params
    const res = await axios.get(routes.GET_CONTACT_US, {
        params: { limit, offset, searchText },
        headers: { Authorization: localStorage.getItem("token") }
    });
    return res.data;
}

const getOrders = async(params) => {
    let { limit, offset, searchText } = params
    const res = await axios.get(routes.ORDER, {
        params: { limit, offset, searchText },
        headers: { Authorization: localStorage.getItem("token") }
    });
    return res.data;
}

const getSubAdmins = async(params) => {
    let { limit, offset, searchText } = params
    const res = await axios.get(routes.SUB_ADMIN, {
        params: { limit, offset, searchText },
        headers: { Authorization: localStorage.getItem("token") }
    });
    return res.data;
}

const addSubAdmin = async(data) => {
    const res = await axios.post(routes.REGISTER, data, {
        headers: { Authorization: localStorage.getItem("token") },
    });
    return res.data;
}

const deleteSubAdmin = async(id) => {
    const res = await axios.delete(routes.SUB_ADMIN+"/"+id, {
        headers: { Authorization: localStorage.getItem("token") }
    });
    return res.data;
}

const updateSubAdmin = async(params) => {
    const { body, id } = params;
    console.log("====><>",body, id);
    const res = await axios.put(routes.SUB_ADMIN+"/"+id, body, {
        headers: { Authorization: localStorage.getItem("token") },
    });
    return res.data
}

const userServices = { 
    getUsers,
    getOrders,
    getUserById,
    getContactUs,
    getSubAdmins,
    addSubAdmin,
    deleteSubAdmin,
    updateSubAdmin
}

export default userServices;