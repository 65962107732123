import { useEffect } from "react";
import PageLoader from '../component/PageLoader';
import EventForm from "../component/EventForm";
import { useDispatch, useSelector } from "react-redux";
import { getEventById } from "../redux/event/event.slice";

const EditEvent = () => { 
    const dispatch = useDispatch();
    const { isLoading, eventId } = useSelector((state) => state.event);

    useEffect(() => { dispatch(getEventById({id: eventId})) }, [dispatch, eventId]);

    if(isLoading) return (<PageLoader />)

    return (<EventForm from="edit" />)
}
export default EditEvent;