import axios from "axios";
import routes from "../routes";

const login = async(data) => {
    const res = await axios.post(routes.LOGIN, data);
    if(res?.data?.statusCode === 200){
        localStorage.setItem("token", res.data.token);
        localStorage.setItem("admin", JSON.stringify(res.data.admin));
    }
    return res.data;
}

const verifyToken = async() => {
    const res = await axios.post(routes.VERIFY_TOKEN, {}, {
        headers: { Authorization: localStorage.getItem("token") },
    });
    if(res?.data?.statusCode === 200){
        localStorage.setItem("token", res.data.token);
        localStorage.setItem("admin", JSON.stringify(res.data.admin));
    }
    return res.data;
}

const authServices = {
    login,
    verifyToken
}

export default authServices;