import axios from "axios";
import routes from "./routes";

const uploadOnS3 = async(params) => {
    try{
        const formData = new FormData();
        if(Array.isArray(params)){
            params.map((img) => formData.append("image", img));
        }
        else{
            formData.append("image", params);
            formData.append("image", params.gif);
        }
        const res = await axios.post(routes.UPLOAD_ON_S3, formData, {
            headers: { "Content-Type": "multipart/form-data" }
        });
        return res.data;
    }
    catch(err){
        return err;
    }
}

export default uploadOnS3;