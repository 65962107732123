import { FaUsers } from "react-icons/fa";
import { FaRupeeSign } from "react-icons/fa";
import { MdAttachMoney } from "react-icons/md";
import { FaCartShopping } from "react-icons/fa6";

const Dashboard = () => {
    return (
        <>
            <div className="container mt-80">
                <div className="row total-box">
                    <div className="col-md-3 total-item border-primary">
                        <div>
                            <h4 className="text-primary">Total Orders</h4>
                            <p>2345</p>
                        </div>
                        <FaCartShopping fontSize={32} />
                    </div>
                    <div className="col-md-3 total-item border-danger">
                        <div>
                            <h4 className="text-danger">Total Earnings</h4>
                            <p className="total-earning">
                                <FaRupeeSign fontSize={16} /> 2345
                            </p>
                        </div>
                        <MdAttachMoney fontSize={32} />
                    </div>
                    <div className="col-md-3 total-item border-warning">
                        <div>
                            <h4 className="text-warning">Total Users</h4>
                            <p>2345</p>
                        </div>
                        <FaUsers fontSize={32} />
                    </div>
                </div>
            </div>
        </>
    )
}
export default Dashboard;