import Moment from "moment";
import { CiCalendar } from "react-icons/ci";
import { IoMdTime } from "react-icons/io";
import { FaRupeeSign } from "react-icons/fa";
import { IoLocationOutline } from "react-icons/io5";
const months = require("../data/Months.json");

const EventCard = (props) => {
    return (
        <div className="event-card-root">
            <div className="d-flex justify-content-center">
                <img alt="logo" src={props.item?.image} />
            </div>
            <div className="event-card-content">
                <h5>
                    {props.item?.name} | {props.item?.type} | {props.item?.city}
                </h5>
                <p>
                    <span>
                        <CiCalendar fontSize="20px" />
                        <span className="time-icon">
                            <IoMdTime fontSize="12px" />
                        </span>
                    </span>
                    <span className="date-time">
                        {console.log("hello",months[Moment(props.item?.date).month()]?.code)}
                        {months[Moment(props.item?.date).month()]?.code}{" "}
                        {Moment(props.item?.date).date()}{" "}|{" "}
                        {Moment(props.item?.date).format('h:mm A')}
                    </span>
                </p>
                <p className="venue-box">
                    <span className="d-flex">
                        <IoLocationOutline fontSize="20px" />
                    </span>
                    <span className="event-venue">
                        {props.item?.venue}, {props.item?.city}
                    </span>
                </p>
                <div className="d-flex align-items-center justify-content-between card-root">
                    <span className="price">
                        {(props.item?.tickets && props.item?.tickets?.length > 0) &&
                        <>
                            <FaRupeeSign fontSize="16px" /> <span>
                                {props.item?.tickets.reduce((min, obj) => Math.min(min, obj.price), Infinity)} Onwards
                            </span>
                        </>
                        }
                    </span>
                </div>
            </div>
        </div>
    )
}
export default EventCard;